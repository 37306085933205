<template>
  <div id="epNav">
    <router-link v-for="item, index in navItems" :to="item.location" class="ep__navItem">
        <span class="ep__number">{{ index + 1 }}</span>
        <span class="ep__title">{{ item.title }}</span>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'Navigation',
    data: function () {
      return {
        navItems: [
          {
            location: '/info',
            title: 'How the voting works'
          },
          {
            location: '/candidates',
            title: 'Your candidates'
          },
          {
            location: '/vote',
            title: 'Your vote'
          }
        ]
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "@/styles/vars.scss";
  #score-election-app {
    #epNav {
      padding: 30px 0;
      display: flex;
      justify-content: space-between;
      max-width: 400px;
      margin: 0 auto;

      .ep__navItem {
        flex: 1;
        display: block;
        color: black;
        text-align: left;
        text-decoration: none;
        padding: 5px;
        border: 1px solid $color-2;
        transition: background-color 0.5s ease;

        &:not(:last-of-type) {
          margin-right: 15px;
        }
        &:hover, &:active {
          background-color: lighten($color-2, 60%);
          color: black;
        }
        &.router-link-exact-active {
          background-color: $color-2;
          color: white;
        }
        span {
          display: block;
          line-height: 1.2em;
        }
        .ep__number {
          font-weight: 700;
        }
      }
    }
  }
</style>
