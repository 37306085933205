<template>
  <div class="ep__page ep__page--info">
  	<Navigation />
    <h1>How the voting works</h1>
    <hr>
    <div v-show="currentStep === 0">
      <p>Your Pension Scheme Trustee has recently been seeking nominations for the position of a Member Nominated Director (MND) to represent the Active membership.</p>
      <p>The Trustee Board is made up of eight Directors, four are nominated by the members of the Scheme and four are appointed by the Company.</p>
      <img src="@/assets/TrusteeGraphic.svg" class="ep__copy-image" alt="The trustee board is made up of four company appointed directors and four member nominated directors">
      <p>Nominations from the Active membership closed on 23 December 2022. We’re pleased to say that ?? people have put themselves forward.</p>
      <p>As we have more applicants than vacancies, we now need to hold a Ballot to decide which member is selected.</p>
      <p>As an Active member of the Scheme you are entitled to vote for your preferred candidate.</p>
      
      <div class="ep__next-prev">
        <router-link to="/"><button class="ep__btn ep__btn-secondary"><img class="ep__icon ep__icon--reversed" src="@/assets/icon--go.svg" alt="Icon go"> Back</button></router-link>
        <button v-on:click="currentStep = 1; scrollToTop()" class="ep__btn ep__btn-primary">Next <img class="ep__icon" src="@/assets/icon--go.svg" alt="Icon go"></button>
      </div>
    </div>

    <div v-show="currentStep === 1">
      <p><strong>Continued...</strong></p>
      <p>The 'Your candidates' page provides a summary of the candidates, with their brief biographies. Just review the candidates and decide who you would like to represent you on the Trustee Board.</p>
      <p>You can only vote for two candidates, if you vote for more than two, your votes will not be counted.</p>
      <p>The Ballot is open from <strong>{{new Date(electionData.start).toLocaleDateString('en-GB', dateOptions)}}</strong> and will run until <strong>5 pm on {{ new Date(electionData.end).toLocaleDateString('en-GB', dateOptions)}}</strong> at which point the Ballot will be closed. Any Ballot papers received after this date will not be counted.</p>
      <p>If you have any questions please contact the Scheme Administrator by telephone on 0118 214 3330.</p>
      <img src="@/assets/XPS_Logo.svg" class="ep__copy-image" alt="EQuiniti logo">
      <p>Below we have added a dynamic timeline to show how the process is progressing.</p>
      <Timeline :electionData="electionData" />

      <div class="ep__next-prev">
        <button v-on:click="currentStep = 0; scrollToTop()" class="ep__btn ep__btn-secondary"><img class="ep__icon ep__icon--reversed" src="@/assets/icon--go.svg" alt="Icon go"> Back</button>
        <router-link to="/candidates"><button class="ep__btn ep__btn-primary">Next <img class="ep__icon" src="@/assets/icon--go.svg" alt="Icon go"></button></router-link>
      </div>

    </div>

  </div>
</template>

<script>
  // @ is an alias to /src
  import Navigation from '@/components/Navigation.vue'
  import Timeline from '@/components/Timeline.vue'

  export default {
    components: {
      Navigation,
      Timeline
    },
    props: {
      electionData: Object
    },
    data: function () {
      return {
        currentStep: 0,
        dateOptions: {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
        }
      }
    },
    methods: {
      scrollToTop() {
        window.scrollTo(0,0);
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/vars.scss";
  #score-election-app {
    
  }
</style>
